
export const storeRepository = {
    getStoreMap: async () => {
        try {
            const url = `${process.env.REACT_APP_STORE_S3_BUCKET}/${process.env.REACT_APP_STORE_JSON_FOLDER}/${process.env.REACT_APP_STORE_MAP_FILENAME}`;

            const response = await fetch(url);

            const storeMap = await response.json();

            return {
                storeMap,
                error: null
            };
        } catch (error) {
            return {
                storeMap: null,
                error: error.message
            }
        }
    },
    getStoreJSON: async (storeId: string, locale: string) => {
        try {
            const url = `${process.env.REACT_APP_STORE_S3_BUCKET}/${process.env.REACT_APP_STORE_JSON_FOLDER}/HARDROCKCAFE_${storeId}_${locale}.json`;

            const response = await fetch(url);

            const storeJson = await response.json();

            return {
                storeJson: storeJson,
                error: null,
            };
        } catch (error) {
            return {
                storeJson: null,
                error: error.message,
            };
        }
    },
};
