export const get100vh = () => {
    calculateViewPortHeight();

    window.addEventListener("resize", () => {
        calculateViewPortHeight();
    });
};

export const calculateViewPortHeight = () => {
    const viewHeight = window.innerHeight;
    const bottomBar = document.getElementById("bottom-bar-container");
    const bagButton = document.getElementById("add-to-bag-btn");
    const finishedButton = document.getElementById("finished-btn");

    const bottomBarHeight = bottomBar === null ? 0 : bottomBar.getBoundingClientRect().height;
    const bagButtonHeight = bagButton === null ? 0 : bagButton.getBoundingClientRect().height;
    const finishedButtonHeight = finishedButton === null ? 0 : finishedButton.getBoundingClientRect().height;
    const buttonHeight = bagButtonHeight + finishedButtonHeight;
    const availableHeight = viewHeight - bottomBarHeight;

    document.documentElement.style.setProperty("--innerheight", `${viewHeight}px`);
    document.documentElement.style.setProperty("--availableheight", `${availableHeight}px`);

    return {
        innerHeight: viewHeight,
        buttonHeight: buttonHeight,
        availableHeight: availableHeight
    }
};
