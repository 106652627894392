/* eslint-disable */
import React from "react";
import { calculateViewPortHeight } from "../helpers/calculate-window-innerheight";

class Page extends React.Component<any, any> {
    buttonRef: any;

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.pageLoading
            ? this.props.setShowLoader(true)
            : this.props.setShowLoader(false);
        
        calculateViewPortHeight();
    }

    componentDidUpdate(previousProps, previousState) {
        if (this.props.pageLoading !== previousProps.pageLoading) {
            this.props.pageLoading
                ? this.props.setShowLoader(true)
                : this.props.setShowLoader(false);
        }

        calculateViewPortHeight();
    }

    render() {
        let className = "page";

        if (this.props.theme == "theme-light") {
            className += ` theme-light`;
        } else if (this.props.theme == "theme-dark") {
            className += ` theme-dark`;
        }

        if (this.props.pageLoading) {
            className += " blur";
        }

        if (this.props.zoomEffect) {
            className += " zoomPage";
        }
        
        return <div className={className}>{this.props.children}</div>;
    }
}

export default Page;
