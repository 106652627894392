import { createContext } from "react";
import { cartRepository } from "../repositories/cart-repository";
import { ICart, ICartItem } from "../models/Cart";

/** Adds a new item to the cart */
export const addCartItem = async (cartState: ICart, setCartState: any, cartItemID: string, recipeID: string, quantity: number, product: any) => {    
    let currentCart: any = { ...cartState };

    if (currentCart === null || !currentCart.cartItems) {
        currentCart = {
            cartItems: [],
        };
    }

    const newCartItem: ICartItem = {
        cartItemID,
        recipeID,
        product: product,
        quantity: quantity,
    };

    currentCart.cartItems.push(newCartItem);
    setCartState(currentCart);
    localStorage.setItem("cart", JSON.stringify(currentCart));
};

/** Removes an item from the cart */
export const removeCartItem = async (cartState: ICart, setCartState: any, cartItemID: string) => {
    const currentCart = { ...cartState };
    const cartItemIndex = currentCart.cartItems.findIndex((cartItem) => cartItem.cartItemID == cartItemID);

    if (cartItemIndex != -1) {
        currentCart.cartItems.splice(cartItemIndex, 1);
        setCartState(currentCart);
        localStorage.setItem("cart", JSON.stringify(currentCart));
    }
};

/** Updates the quantity of an item in the cart */
export const updateCartItemQuantity = (cartState: ICart, setCartState: any, cartItemID: string, quantity: number) => {    
    const currentCart = { ...cartState };

    const cartItemIndex = currentCart.cartItems.findIndex((cartItem) => cartItem.cartItemID == cartItemID);

    if (cartItemIndex != -1) {
        currentCart.cartItems[cartItemIndex].quantity = quantity;
    }

    setCartState(currentCart);
    localStorage.setItem("cart", JSON.stringify(currentCart));
};

// We don't have edit functionality on white label so this is not currently used, but may be needed in the actual project
export const updateCartItem = (cartState: ICart, setCartState: any, cartItemID: string, recipeID: string, product: any) => {
    const currentCart = { ...cartState };

    const cartItemIndex = currentCart.cartItems.findIndex((cartItem) => cartItem.cartItemID == cartItemID);

    if (cartItemIndex != -1) {
        currentCart.cartItems[cartItemIndex].recipeID = recipeID;
        currentCart.cartItems[cartItemIndex].product = product;
    }

    setCartState(currentCart);
    localStorage.setItem("cart", JSON.stringify(currentCart));
};

// We don't have edit functionality on white label so this is not currently used, but may be needed in the actual project
export const updateCartItemProductSKU = (cartState: any, setCartState: any, cartItemIndex: number, newProductSkuIndex: number) => {
    const currentCart = { ...cartState };

    const newProductSKU = { ...currentCart.cartItems[cartItemIndex].product.stock[newProductSkuIndex] };

    const cartItemProduct = { ...currentCart.cartItems[cartItemIndex].product };
    cartItemProduct.sku = newProductSKU;

    currentCart.cartItems[cartItemIndex].product = cartItemProduct;

    setCartState(currentCart);
    localStorage.setItem("cart", JSON.stringify(currentCart));
};

/** Reset the cart. Use after an order has been placed. */
export const clearCart = (setCartState: any) => {
    const currentCart = {
        cartItems: [],
    };

    setCartState(currentCart);
    localStorage.setItem("cart", JSON.stringify(currentCart));
};

const CartContext = createContext({
    cart: {
        cartItems: [],
    },
    addCartItem: null,
    removeCartItem: null,
    updateCartItemQuantity: null,
    updateCartItem: null,
    updateCartItemProductSKU: null,
    clearCart: null,
});

export default CartContext;
