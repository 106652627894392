import { initialize, plugin } from "react-ga";

export const enableAnalytics = () => {
    try {
        if (typeof window !== "undefined") {
            initialize(process.env.GOOGLE_ANALYTICS_TRACKING_ID);
            plugin.require('ec');
        }
    } catch (error) {
        console.log(error);
    }
}