/* eslint-disable */
export const getCookieExpirationDate = (kioskMode: boolean = false) => {
    let futureDate: Date = new Date();
    let step = 1;

    if (kioskMode == true) {
        step = 365;
    }

    futureDate.setDate(futureDate.getDate() + step);
    return futureDate;
};
