/* eslint-disable */
import React from "react";
import TranslateContext from "../providers/TranslateContext";
import styles from "../styles/ScreensaverModal.module.scss";

const ScreensaverModal: React.FC<any> = (props) => {
    return (
        <React.Fragment>
            <TranslateContext.Consumer>
                {({ t }) => (
                    <React.Fragment>
                        <div className={styles[`screensaver-modal-overlay`]}>
                            <div className={styles[`screensaver-modal-grid`]}>
                                <div className={styles[`screensaver-modal-grid-header`]}></div>
                                <div className={styles[`screensaver-modal-grid-left-side`]}></div>
                                <div className={styles[`screensaver-modal-grid-body`]}>
                                    <div className={styles[`screensaver-modal-grid-body-content`]}>
                                        <div className={styles[`screensaver-modal-grid-body-text`]}>
                                            {t(`screensaverModal.screensaver_modal_text`)}
                                        </div>
                                        <div className={styles[`screensaver-modal-grid-body-button`]}>
                                            {t(`screensaverModal.screensaver_modal_button`)}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles[`screensaver-modal-grid-right-side`]}></div>
                                <div className={styles[`screensaver-modal-grid-footer`]}></div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </TranslateContext.Consumer>
        </React.Fragment>
    );
};

export default ScreensaverModal;
