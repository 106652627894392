/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { DevPasswordOverlay } from "./components/DevPasswordOverlay";
import { Meta } from "./components/Meta";
import "./styles/globals.scss";

import CartContext, {
    addCartItem,
    removeCartItem,
    updateCartItemQuantity,
    updateCartItem,
    updateCartItemProductSKU,
    clearCart,
} from "./providers/CartContext";
import TranslateContext, { t, changeLocale } from "./providers/TranslateContext";
import { useCookies } from "react-cookie";
import { CookiesProvider } from "react-cookie";
import locales from "./locales/available_locales.json";
import { storeRepository } from "./repositories/store-repository";
import { useHistory } from "react-router-dom";
import { getQueryParametersFromUrl } from "./helpers/get-query-parameters-from-url";
import Page from "./components/Page";

import LoaderForBlurEffect from "./components/LoaderForBlurEffect";
import ScreensaverModal from "./components/ScreensaverModal";
import { Screensaver } from "./components/Screensaver";
import { getKioskModeFromKioskString } from "./helpers/get-kiosk-mode-from-kiosk-string";
import ErrorBoundary from "./components/ErrorBoundary";
import { getCookie } from "./helpers/get-cookie";
import ProgressBar from "./components/ProgressBar";
import { getCookieExpirationDate } from "./helpers/get-cookie-expiration-date";
import { cookieParameterMap } from "./helpers/cookie-parameter-map";
import { Locale } from "./classes/Locale";
import ProductsContext from "./providers/ProductsContext";
import { productsRepository } from "./repositories/products-repository";
import { enableAnalytics } from "./helpers/analytics/enable-analytics";
import AnalyticsContext from "./providers/AnalyticsContext";
import { calculateViewPortHeight } from "./helpers/calculate-window-innerheight";
import HomepageState from "./pages/HomepageState";

const Homepage = React.lazy(() => import("./pages/Homepage"));
const ProductPage = React.lazy(() => import("./pages/product-designer/design"));
const CartPage = React.lazy(() => import("./pages/Cart"));
const CheckoutPage = React.lazy(() => import("./pages/Checkout"));
const OrderConfirmationPage = React.lazy(() => import("./pages/OrderConfirmation"));
const StaticPage = React.lazy(() => import("./pages/StaticPage"));
const TermsAndConditions = React.lazy(() => import("./components/TermsAndConditions"));

function App(props) {
    const [cookies, setCookie] = useCookies(JSON.parse(localStorage.getItem("cart")));
    const [analyticsState, setAnalyticsState] = useState(null);
    const [cartState, setCartState] = useState(JSON.parse(localStorage.getItem("cart")));
    const [localisationModalVisible, setLocalisationModalVisible] = useState(false);
    const [kioskMode, setKioskMode] = useState(false);
    const [disableLangChange, setDisableLangChange] = useState(false);
    const [confirmedOrders, setConfirmedOrders] = useState(false);
    const [store, setStore] = useState({
        id: "",
        storeCode: "",
        name: "",
        city: "",
        defaultLocale: "",
        defaultCurrency: "",
        deployedURLS: [],
        locales: [],
        currency: {
            name: "",
            code: "",
            symbol: "",
            format: "",
        },
    });
    const [storeJSON, setStoreJSON] = useState(null);
    const [collectionNote, setCollectionNote] = useState(false);
    const [dataApiBaseUrl, setDataApiBaseUrl] = useState("");
    const [imageApiBaseUrl, setImageApiBaseUrl] = useState("");
    const [locale, setLocale] = useState(cookies.locale ? cookies.locale : null);
    const [availableLocales, setAvailableLocales] = useState(null);
    const [disableStorePickup, setDisableStorePickup] = useState(false);
    const [screensaverModalVisible, setScreensaverModalVisible] = useState(false);
    const [screensaverVideoVisible, setScreensaverVideoVisible] = useState(false);
    const [notificationVisible, setNotificationVisible] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [theme, setTheme] = useState("theme-light");
    const [pageLoading, setPageLoading] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const pageRef = useRef(null);
    const historyHook = useHistory();
    const location = useLocation();
    const [zoom, setZoom] = useState(false);
    const [shippingCountries, setShippingCountries] = useState([]);
    const [quickAdd, setQuickAdd] = useState(false);
    const [products, setProducts] = useState();
    const screensaverVideoVisibleRef = React.useRef(screensaverVideoVisible);
    const storeJsonRef = React.useRef(storeJSON);
    const storeRef = React.useRef(store);
    const routes = ["/", "/choose", "/product-designer", "/cart", "/checkout", "/order-confirmation", "/privacy-policy", "/terms-conditions"];
    let modalTimeout, videoTimeout;

    const _setScreensaverVideoVisible = (data) => {
        screensaverVideoVisibleRef.current = data;
        setScreensaverVideoVisible(data);
    };

    const _setStore = (store) => {
        storeRef.current = store;
        setStore(store);
    };

    const _setStoreJSON = (data) => {
        storeJsonRef.current = data;
        setStoreJSON(data);
    };

    useEffect(() => {
        const history = historyHook;
        const path = location.pathname;
        const validRoute = routes.includes(path) || path.startsWith("/product-designer/");

        //Set cart
        const cart = JSON.parse(localStorage.getItem("cart"));

        if (cart == null) {
            const cart = {
                cartItems: [],
            };
            localStorage.setItem("cart", JSON.stringify(cart));
        }

        const { kioskModeUpdated } = setupKioskMode();

        setCartState(cart);

        (async () => {
            const storeIdFromPath = path.substring(1);
            const getStoreMapResponse = await storeRepository.getStoreMap();

            if (getStoreMapResponse.error) {
                throw new Error(`Couldn't retrieve store map - ${getStoreMapResponse.error}`);
            }

            // Set up analytics

            let analytics = {
                analyticsDisabled: false,
            };

            if (analytics.analyticsDisabled === false) {
                enableAnalytics();
            }

            setAnalyticsState(analytics);

            const retrievedStoreMap = getStoreMapResponse.storeMap.Stores;
            let validStore = retrievedStoreMap.find((store) => store.storeCode == storeIdFromPath);
            // Store cookie
            let store = cookies.store;

            if (!validStore && !store && path === "/") {
                validStore = retrievedStoreMap.find((store) => store.storeCode == "create");
            }

            if (validStore) {
                setupStore(validStore, kioskModeUpdated);
                history.push("/");
            } else if (!store || !validRoute) {
                history.push("/error");
            } else {
                setupStore(store, kioskModeUpdated);
            }
        })();

        return () => {
            removeEventListeners();
            removeEventListenersForScreensaver();
        };
    }, []);

    useEffect(() => {
        const fetchProducts = async () => {
            let products = await productsRepository.getProducts();
            setProducts(products);
        };
        fetchProducts();
    }, []);

    const setupEventListeners = () => {
        window.addEventListener("resize", setWindowDimensionsInCSS);
    }

    const setupEventListenersForScreensaver = () => {
        window.addEventListener("click", detectUserAction);
        window.addEventListener("touchend", detectUserAction);
        window.addEventListener("keyup", detectUserAction);
    };

    const removeEventListeners = () => {
         window.removeEventListener("resize", setWindowDimensionsInCSS);
    }

    const removeEventListenersForScreensaver = () => {
        window.removeEventListener("click", detectUserAction);
        window.removeEventListener("touchend", detectUserAction);
        window.removeEventListener("keyup", detectUserAction);
    };

    const getKioskCookie = () => {
        const kioskCookie = getCookie(cookieParameterMap.kiosk);
        const kioskMode = getKioskModeFromKioskString(kioskCookie);
        return kioskMode;
    };

    const setupKioskMode = () => {
        let kioskModeUpdated = false;
        let kioskCookie = getCookie(cookieParameterMap.kiosk);
        let kioskMode = getKioskModeFromKioskString(kioskCookie);

        const queryParameters = getQueryParametersFromUrl(window.location.href);
        const kioskParam = queryParameters[cookieParameterMap.kiosk];

        if (kioskParam != null && (kioskParam == "true" || kioskParam == "false")) {
            kioskModeUpdated = true;
            kioskMode = getKioskModeFromKioskString(kioskParam);
            const expiryDate = getCookieExpirationDate(kioskMode);
            setCookie(cookieParameterMap.kiosk, kioskParam, {
                path: "/",
                httpOnly: false,
                expires: expiryDate,
                sameSite: "strict",
            });
        } else if (kioskCookie == null || kioskCookie == "" || kioskCookie == "undefined") {
            kioskModeUpdated = true;
            kioskMode = false;
            const expiryDate = getCookieExpirationDate(kioskMode);
            setCookie(cookieParameterMap.kiosk, kioskMode, {
                path: "/",
                httpOnly: false,
                expires: expiryDate,
                sameSite: "strict",
            });
        }

        setKioskMode(kioskMode);

        return {kioskModeUpdated};
    };

    const setDisableLangChangeCookie = (kiosk: boolean, kioskModeUpdated: boolean) => {
        const disableLangChangeCookie = getCookie(cookieParameterMap.disableLangChange);
        let disableLangChangeString = disableLangChangeCookie;

        const queryParameters = getQueryParametersFromUrl(window.location.href);
        const disableLangChangeParam = queryParameters[cookieParameterMap.disableLangChange];

        if (disableLangChangeParam == "true" || disableLangChangeParam == "false") {
            disableLangChangeString = disableLangChangeParam;
            const expirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.disableLangChange, disableLangChangeParam, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: expirationDate,
            });
        } else if (disableLangChangeCookie == null || disableLangChangeCookie == "" || disableLangChangeCookie == "undefined") {
            disableLangChangeString = "false";
            const expirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.disableLangChange, false, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: expirationDate,
            });
        } else if (kioskModeUpdated == true) {
            const expirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.disableLangChange, disableLangChangeString, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: expirationDate,
            });
        }

        let disableLangChange = false;

        if (disableLangChangeString == "true") {
            disableLangChange = true;
        }

        setDisableLangChange(disableLangChange);
    };

    const setDebugCookie = (kiosk: boolean, kioskModeUpdated: boolean) => {
        const debugCookie = getCookie(cookieParameterMap.debug);

        const queryParameters = getQueryParametersFromUrl(window.location.href);
        const debugParam = queryParameters[cookieParameterMap.debug];

        if (debugParam == "true" || debugParam == "false") {
            const cookieExpirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.debug, debugParam, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: cookieExpirationDate,
            });
        } else if (debugCookie == null || debugCookie == "" || debugCookie == "undefined") {
            const cookieExpirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.debug, false, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: cookieExpirationDate,
            });
        } else if (kioskModeUpdated == true) {
            const cookieExpirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.debug, debugCookie, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: cookieExpirationDate,
            });
        }
    };

    const setupQuickAdd = (kiosk: boolean, kioskModeUpdated: boolean) => {
        const quickAddCookie = getCookie(cookieParameterMap.quickAdd);
        let quickAddString = quickAddCookie;

        const queryParameters = getQueryParametersFromUrl(window.location.href);
        const quickAddParam = queryParameters[cookieParameterMap.quickAdd];

        if (quickAddParam == "true" || quickAddParam == "false") {
            quickAddString = quickAddParam;
            const cookieExpirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.quickAdd, quickAddParam, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: cookieExpirationDate,
            });
        } else if (quickAddCookie == null || quickAddCookie == "" || quickAddCookie == "undefined") {
            quickAddString = "false";
            const cookieExpirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.quickAdd, false, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: cookieExpirationDate,
            });
        } else if (kioskModeUpdated == true) {
            const cookieExpirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.quickAdd, quickAddString, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: cookieExpirationDate,
            });
        }

        let quickAdd = false;

        if (quickAddString == "true") {
            quickAdd = true;
        }

        setQuickAdd(quickAdd);
    };

    const setLocalPrintCookie = (kiosk: boolean, kioskModeUpdated: boolean) => {
        const localPrintCookie = getCookie(cookieParameterMap.localPrint);

        const queryParameters = getQueryParametersFromUrl(window.location.href);
        const localPrintParam = queryParameters[cookieParameterMap.localPrint];

        if (localPrintParam == "true" || localPrintParam == "false") {
            const cookieExpirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.localPrint, localPrintParam, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: cookieExpirationDate,
            });
        } else if (localPrintCookie == null || localPrintCookie == "" || localPrintCookie == "undefined") {
            const cookieExpirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.localPrint, false, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: cookieExpirationDate,
            });
        } else if (kioskModeUpdated == true) {
            const cookieExpirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.localPrint, localPrintCookie, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: cookieExpirationDate,
            });
        }
    };

    const setBarcodeFormatCookie = (kiosk: boolean, kioskModeUpdated: boolean) => {
        const barcodeFormatCookie = getCookie(cookieParameterMap.barcodeFormat);

        const queryParameters = getQueryParametersFromUrl(window.location.href);
        const barcodeFormatParam = queryParameters[cookieParameterMap.barcodeFormat];

        if (barcodeFormatParam != null && barcodeFormatParam != "") {
            const cookieExpirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.barcodeFormat, barcodeFormatParam, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: cookieExpirationDate,
            });
        } else if (barcodeFormatCookie == null || barcodeFormatCookie == "" || barcodeFormatCookie == "undefined") {
            const cookieExpirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.barcodeFormat, "UPC", {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: cookieExpirationDate,
            });
        } else if (kioskModeUpdated == true) {
            const cookieExpirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.barcodeFormat, barcodeFormatCookie, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: cookieExpirationDate,
            });
        }
    };

    const setCollectionNoteCookie = (kiosk: boolean, kioskModeUpdated: boolean) => {
        const collectionNoteCookie = getCookie(cookieParameterMap.collectionNote);
        let collectionNoteString = collectionNoteCookie;

        const queryParameters = getQueryParametersFromUrl(window.location.href);
        const collectionNoteParam = queryParameters[cookieParameterMap.collectionNote];

        if (collectionNoteParam == "true" || collectionNoteParam == "false") {
            collectionNoteString = collectionNoteParam;
            const cookieExpirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.collectionNote, collectionNoteParam, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: cookieExpirationDate,
            });
        } else if (collectionNoteCookie == null || collectionNoteCookie == "" || collectionNoteCookie == "undefined") {
            collectionNoteString = "false";
            const cookieExpirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.collectionNote, false, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: cookieExpirationDate,
            });
        } else if (kioskModeUpdated == true) {
            const cookieExpirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.collectionNote, collectionNoteString, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: cookieExpirationDate,
            });
        }

        let collectionNote = false;

        if (collectionNoteString == "true") {
            collectionNote = true;
        }

        setCollectionNote(collectionNote);
    };

    const setConfirmedOrdersCookie = (kiosk: boolean, kioskModeUpdated: boolean) => {
        const confirmedOrdersCookie = getCookie(cookieParameterMap.confirmedOrders);
        let confirmedOrdersString = confirmedOrdersCookie;

        const queryParameters = getQueryParametersFromUrl(window.location.href);
        const confirmedOrdersParam = queryParameters[cookieParameterMap.confirmedOrders];

        if (confirmedOrdersParam == "true" || confirmedOrdersParam == "false") {
            confirmedOrdersString = confirmedOrdersParam;
            const cookieExpirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.confirmedOrders, confirmedOrdersParam, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: cookieExpirationDate,
            });
        } else if (confirmedOrdersCookie == null || confirmedOrdersCookie == "" || confirmedOrdersCookie == "undefined") {
            confirmedOrdersString = "false";
            const cookieExpirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.confirmedOrders, false, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: cookieExpirationDate,
            });
        } else if (kioskModeUpdated == true) {
            const cookieExpirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.confirmedOrders, confirmedOrdersCookie, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: cookieExpirationDate,
            });
        }

        let confirmedOrders = false;

        if (confirmedOrdersString == "true") {
            confirmedOrders = true;
        }

        setConfirmedOrders(confirmedOrders);
    };

    const setDisableStorePickupCookie = (kiosk: boolean, kioskModeUpdated: boolean) => {
        const disableStorePickupCookie = getCookie(cookieParameterMap.disableStorePickup);
        let disableStorePickupString = disableStorePickupCookie;

        const queryParameters = getQueryParametersFromUrl(window.location.href);
        const disableStorePickupParam = queryParameters[cookieParameterMap.disableStorePickup];

        if (disableStorePickupParam == "true" || disableStorePickupParam == "false") {
            disableStorePickupString = disableStorePickupParam;
            const cookieExpirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.disableStorePickup, disableStorePickupParam, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: cookieExpirationDate,
            });
        } else if (disableStorePickupCookie == null || disableStorePickupCookie == "" || disableStorePickupCookie == "undefined") {
            disableStorePickupString = "false";
            const cookieExpirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.disableStorePickup, false, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: cookieExpirationDate,
            });
        } else if (kioskModeUpdated == true) {
            const cookieExpirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.disableStorePickup, disableStorePickupString, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: cookieExpirationDate,
            });
        }

        let disableStorePickup = false;

        if (disableStorePickupString == "true") {
            disableStorePickup = true;
        }

        setDisableStorePickup(disableStorePickup);
    };

    const updateQuickAddCookie = (newValue: boolean) => {
        const kiosk = kioskMode;
        const quickAddCookie = getCookie(cookieParameterMap.quickAdd);
        let quickAddString = quickAddCookie;

        let newValueString = "false";

        if (newValue == true) {
            newValueString = "true";
        }

        if (newValueString == "true" || newValueString == "false") {
            quickAddString = newValueString;
            const cookieExpirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.quickAdd, newValueString, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: cookieExpirationDate,
            });
        } else if (quickAddCookie == null || quickAddCookie == "" || quickAddCookie == "undefined") {
            quickAddString = "false";
            const cookieExpirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.quickAdd, false, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: cookieExpirationDate,
            });
        }

        let quickAdd = false;

        if (quickAddString == "true") {
            quickAdd = true;
        }

        setQuickAdd(quickAdd);
    };

    const updateDisableStorePickupCookie = (newValue: boolean) => {
        const kiosk = kioskMode;
        const disableStorePickupCookie = getCookie(cookieParameterMap.disableStorePickup);
        let disableStorePickupString = disableStorePickupCookie;

        let newValueString = "false";

        if (newValue == true) {
            newValueString = "true";
        }

        if (newValueString == "true" || newValueString == "false") {
            disableStorePickupString = newValueString;
            const cookieExpirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.disableStorePickup, newValueString, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: cookieExpirationDate,
            });
        } else if (disableStorePickupCookie == null || disableStorePickupCookie == "" || disableStorePickupCookie == "undefined") {
            disableStorePickupString = "false";
            const cookieExpirationDate = getCookieExpirationDate(kiosk);
            setCookie(cookieParameterMap.disableStorePickup, false, {
                path: "/",
                httpOnly: false,
                sameSite: "strict",
                expires: cookieExpirationDate,
            });
        }

        let disableStorePickup = false;

        if (disableStorePickupString == "true") {
            disableStorePickup = true;
        }

        setDisableStorePickup(disableStorePickup);
    };

    const updateStoreJson = async (storeId: string, locale: Locale) => {
        const getStoreJsonResponse = await storeRepository.getStoreJSON(storeId, locale.Code);

        if (getStoreJsonResponse.error != null) {
            throw new Error(getStoreJsonResponse.error);
        }

        _setStoreJSON(getStoreJsonResponse.storeJson);

        const shippingCountries = getStoreJsonResponse.storeJson.Shipping_Countries;
        setShippingCountries(shippingCountries);

        return getStoreJsonResponse.storeJson;
    };

    const _changeLocale = async (updatedLocale: Locale, store_id?: string, kiosk: boolean = kioskMode) => {
        changeLocale(setLocale, setCookie, updatedLocale, kiosk);

        let storeId = store.storeCode;

        if (store_id != null) {
            storeId = store_id;
        }

        const storeJson = await updateStoreJson(storeId, updatedLocale);

        return {
            updatedLocale,
            storeJson,
        };
    };

    const _t = (key: string, data: any = null) => {
        return t(locale, key, data);
    };

    const _addCartItem = (cartItemID: string, recipeID: string, quantity: number, product: any) => {
        addCartItem(cartState, setCartState, cartItemID, recipeID, quantity, product);
    };

    const _removeCartItem = (cartItemID: string) => {
        removeCartItem(cartState, setCartState, cartItemID);
    };

    const _updateCartItemQuantity = (cartItemID: string, quantity: number) => {
        updateCartItemQuantity(cartState, setCartState, cartItemID, quantity);
    };

    const _updateCartItem = (cartItemID: string, recipeID: string, product: any) => {
        updateCartItem(cartState, setCartState, cartItemID, recipeID, product);
    };

    const _updateCartItemProductSKU = (cartItemIndex: number, newProductSkuIndex: number) => {
        updateCartItemProductSKU(cartState, setCartState, cartItemIndex, newProductSkuIndex);
    };
    const _clearCart = () => {
        clearCart(setCartState);
    };

    const showLocalisationModal = () => {
        setLocalisationModalVisible(true);
    };

    const hideLocalisationModal = () => {
        setLocalisationModalVisible(false);
    };

    const showNotification = (message) => {
        setNotificationVisible(true);
        setNotificationMessage(message);

        setTimeout(() => {
            setNotificationVisible(false);
            setNotificationMessage("");
        }, 4000);
    };

    const blurEntirePage = () => {
        setPageLoading(true);
    };
    const unblurEntirePage = () => {
        setPageLoading(false);
    };

    const setupStore = async (store, kioskModeUpdated) => {
        try {
            const cookieExpirationDate = getCookieExpirationDate(kioskMode);

            setCookie(cookieParameterMap.store, store, {
                path: "/",
                httpOnly: false,
                expires: cookieExpirationDate,
                sameSite: "strict",
            });
            _setStore(store);

            setDebugCookie(kioskMode, kioskModeUpdated);
            setupQuickAdd(kioskMode, kioskModeUpdated);
            setDisableLangChangeCookie(kioskMode, kioskModeUpdated);
            setLocalPrintCookie(kioskMode, kioskModeUpdated);
            setBarcodeFormatCookie(kioskMode, kioskModeUpdated);
            setCollectionNoteCookie(kioskMode, kioskModeUpdated);
            setConfirmedOrdersCookie(kioskMode, kioskModeUpdated);
            setDisableStorePickupCookie(kioskMode, kioskModeUpdated);

            let localesForStore = [...store.locales];
            // Set to the first element of the locales array, en_US
            // Use store's defaultLocale field if needed!
            let localeToSet = localesForStore[0];

            const localeCookie = cookies[cookieParameterMap.locale];
            if (localeCookie != null && localeCookie != "") {
                localeToSet = localeCookie;
            }

            setLocale(localeToSet);
            setAvailableLocales(localesForStore);
            const changeLocaleResult = await _changeLocale(localeToSet, store.storeCode, kioskMode);

            const storeJson = changeLocaleResult.storeJson;

            setupEventListeners();

            if (kioskMode == true && storeJson.Screensaver != null) {
                setupEventListenersForScreensaver();
                const screensaverModalTimeout = storeJson.ScreensaverModalTimeout;
                const screensaverVideoTimeout = storeJson.ScreensaverVideoTimeout;

                const timeouts = {
                    screensaverModalTimeout,
                    screensaverVideoTimeout,
                };
                setDisplayScreensaverModalTimeout(timeouts);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const setDisplayScreensaverModalTimeout = (timeouts: any) => {
        if (pageRef.current != null) {
            const timeoutDuration = parseInt(timeouts.screensaverModalTimeout) || 20000;
            modalTimeout = setTimeout(() => {
                showScreensaverModal(timeouts);
            }, timeoutDuration);
        }
    };

    const setDisplayScreensaverVideoTimeout = (timeouts: any) => {
        const timeoutDuration = parseInt(timeouts.screensaverVideoTimeout) || 60000;
        videoTimeout = setTimeout(() => {
            showScreensaverVideo();
        }, timeoutDuration);
    };

    const showScreensaverModal = (storeJson: any) => {
        setScreensaverModalVisible(true);
        setDisplayScreensaverVideoTimeout(storeJson);
    };

    const showScreensaverVideo = () => {
        clearTimeout(modalTimeout);
        _setScreensaverVideoVisible(true);
    };

    const hideScreensaver = () => {
        const screensaverModalTimeout = storeJsonRef.current.ScreensaverModalTimeout;
        const screensaverVideoTimeout = storeJsonRef.current.ScreensaverVideoTimeout;
        const timeouts = { screensaverModalTimeout, screensaverVideoTimeout };
        clearTimeout(modalTimeout);
        clearTimeout(videoTimeout);
        setDisplayScreensaverModalTimeout(timeouts);
        setScreensaverModalVisible(false);

        if (screensaverVideoVisibleRef.current === true) {
            _setScreensaverVideoVisible(false);
            setZoom(false);
            const history = historyHook;
            history.push("/");
            _clearCart();
        }
    };

    const detectUserAction = (event) => {
        const isKiosk = getKioskCookie();
        if (isKiosk == true) {
            setTimeout(() => hideScreensaver(), 100);
        }
    };

    const toggleTheme = () => {
        if (theme == "theme-light") {
            setTheme("theme-dark");
        } else {
            setTheme("theme-light");
        }
    };

    const setWindowDimensionsInCSS = () => {
        calculateViewPortHeight();
    }

    return (
        <React.Fragment>
            <CookiesProvider>
                <TranslateContext.Provider
                    value={{
                        t: _t,
                        locale: locale,
                        availableLocales: availableLocales,
                        changeLocale: _changeLocale,
                    }}
                >
                    <AnalyticsContext.Provider
                        value={{
                            analytics: analyticsState,
                            setAnalyticsDisabled: null,
                        }}
                    >
                        <ProductsContext.Provider value={products}>
                            <CartContext.Provider
                                value={{
                                    cart: cartState,
                                    addCartItem: _addCartItem,
                                    removeCartItem: _removeCartItem,
                                    updateCartItemQuantity: _updateCartItemQuantity,
                                    updateCartItem: _updateCartItem,
                                    updateCartItemProductSKU: _updateCartItemProductSKU,
                                    clearCart: _clearCart,
                                }}
                            >
                                <ErrorBoundary>
                                    <Meta />

                                    {(process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT == "dev" ||
                                        process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT == "prod") && (
                                        <noscript
                                            dangerouslySetInnerHTML={{
                                                __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_ANALYTICS_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
                                            }}
                                        ></noscript>
                                    )}

                                    {process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT == "dev" && <DevPasswordOverlay kioskMode={kioskMode} />}

                                    {showLoader && <LoaderForBlurEffect timeout={200} />}

                                    <Page
                                        ref={pageRef}
                                        zoomEffect={zoom}
                                        setShowLoader={setShowLoader}
                                        showLoader={showLoader}
                                        pageLoading={pageLoading}
                                        detectUserAction={detectUserAction}
                                        theme={theme}
                                    >
                                        <React.Suspense fallback={<ProgressBar />}>
                                            <Switch>
                                                <Route path="/" exact>
                                                    <Homepage
                                                        storeID={store.storeCode}
                                                        storeJSON={storeJSON}
                                                        disableStorePickup={disableStorePickup}
                                                        kioskMode={kioskMode}
                                                        {...props}
                                                    />
                                                </Route>

                                                <Route path="/choose" exact>
                                                    <Homepage
                                                        storeID={store.storeCode}
                                                        storeJSON={storeJSON}
                                                        disableStorePickup={disableStorePickup}
                                                        kioskMode={kioskMode}
                                                        defaultHomepageState={HomepageState.HOMEPAGE_CHOOSE_PRODUCT}
                                                        {...props}
                                                    />
                                                </Route>

                                                <Route
                                                    path="/product-designer/:recipe"
                                                    render={(props) => {
                                                        return (
                                                            <ProductPage
                                                                showNotification={showNotification}
                                                                blur={blurEntirePage}
                                                                unblur={unblurEntirePage}
                                                                notificationVisible={notificationVisible}
                                                                notificationMessage={notificationMessage}
                                                                setZoom={setZoom}
                                                                store={store}
                                                                storeJSON={storeJSON}
                                                                zoom={zoom}
                                                                key={props.match.params.recipe}
                                                                {...props}
                                                            />
                                                        );
                                                    }}
                                                />

                                                <Route path="/cart" exact>
                                                    <CartPage
                                                        store={store}
                                                        storeJSON={storeJSON}
                                                        locale={locale}
                                                        quickAdd={quickAdd}
                                                        disableStorePickup={disableStorePickup}
                                                        kioskMode={kioskMode}
                                                    />
                                                </Route>

                                                <Route path="/checkout" exact>
                                                    <CheckoutPage store={store} storeJSON={storeJSON} kioskMode={kioskMode} />
                                                </Route>
                                                <Route path="/order-confirmation" exact>
                                                    <OrderConfirmationPage storeJSON={storeJSON} kioskMode={kioskMode} {...props} />
                                                </Route>
                                                <Route
                                                    path="/error"
                                                    render={(props) => {
                                                        return <ErrorBoundary {...props} />;
                                                    }}
                                                />
                                                <Route path="/order-confirmation" exact>
                                                    <OrderConfirmationPage store_json={storeJSON} kioskMode={kioskMode} {...props} />
                                                </Route>
                                                <Route path="/privacy-policy" exact>
                                                    <StaticPage storeJSON={storeJSON} contentKey={"privacyPolicy"} />
                                                </Route>
                                                <Route path="/terms-conditions" exact>
                                                    <TermsAndConditions />
                                                </Route>
                                                <Route path="/terms-of-use" exact>
                                                    <StaticPage storeJSON={storeJSON} contentKey={"termsOfUse"} />
                                                </Route>
                                                <Route path="/cookie-policy" exact>
                                                    <StaticPage storeJSON={storeJSON} contentKey={"cookiePolicy"} />
                                                </Route>
                                                <Route
                                                    path="/error"
                                                    render={(props) => {
                                                        return <ErrorBoundary {...props} />;
                                                    }}
                                                />
                                            </Switch>
                                        </React.Suspense>
                                        {screensaverModalVisible && <ScreensaverModal />}
                                        {screensaverVideoVisible && storeJSON.Screensaver != null && <Screensaver videoUrl={storeJSON.Screensaver} />}
                                    </Page>
                                </ErrorBoundary>
                            </CartContext.Provider>
                        </ProductsContext.Provider>
                    </AnalyticsContext.Provider>
                </TranslateContext.Provider>
            </CookiesProvider>
        </React.Fragment>
    );
}

export default App;
