
const { REACT_APP_PRODUCTS_API_BASE_URL, REACT_APP_TENANT_ID } = process.env;

export const productsRepository = {

    getProducts: async () => {
        try {
            const getProductsUrl = `${REACT_APP_PRODUCTS_API_BASE_URL}/${REACT_APP_TENANT_ID}/products`;
    
            const getProductsResult = await fetch(getProductsUrl);
            const response = await getProductsResult.json();
            
            return response;
        } catch (error) {
            console.log(error);
        }
    },

    getProduct: async (productID: string) => {
        try {
            const getSigleProductUrl = `${REACT_APP_PRODUCTS_API_BASE_URL}/${REACT_APP_TENANT_ID}/products/${productID}`;

            const getProductsResult = await fetch(getSigleProductUrl);        
            const response = await getProductsResult.json();
            
            return response;
        } catch (error) {
            console.log(error);
        }
    }
}