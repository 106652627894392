/* eslint-disable */
import React from "react";
import { cookieParameterMap } from "../helpers/cookie-parameter-map";
import { getCookieExpirationDate } from "../helpers/get-cookie-expiration-date";

import { Locale } from "../classes/Locale";

// // Import translation files
import en_gb from "../locales/en_gb.json";
import en_us from "../locales/en_us.json";
import zh_cn from "../locales/zh_cn.json";
import zh_tw from "../locales/zh_tw.json";
import ja_jp from "../locales/ja_jp.json";
import de_de from "../locales/de_de.json";

// Map language code to translation JSON file
const locales = {
    en_GB: en_gb,
    en_US: en_us,
    zh_CN: zh_cn,
    zh_TW: zh_tw,
    ja_JP: ja_jp,
    de_DE: de_de
};

// // Translate function
export const t = (chosenLocale: Locale, key: any, data: any) => {
    let json;

    if (chosenLocale != null && locales[chosenLocale.Code] != null) {
        json = locales[chosenLocale.Code];
    } else {
        json = locales["en_US"]; // Default locale
    }

    const elements = key.split(".");

    for (let i = 0; i < elements.length; i++) {
        const param = elements[i];
        json = json[param];
    }

    if (data != null) {
        json = json.replace("%%", data);
    }

    return json;
};

export const changeLocale = (
    setLocaleState: any,
    setCookie: any,
    locale: Locale,
    kioskMode: boolean = false
) => {
    setLocaleState(locale);
    setCookie(cookieParameterMap.locale, locale, {
        path: "/",
        expires: getCookieExpirationDate(kioskMode),
        sameSite: "strict",
    });
};

const TranslateContext = React.createContext({
    t: null,
    locale: null,
    availableLocales: null,
    changeLocale: null,
});

export default TranslateContext;
