/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import { cookieParameterMap } from "../helpers/cookie-parameter-map";
import { getCookieExpirationDate } from "../helpers/get-cookie-expiration-date";
import styles from "../styles/PasswordOverlay.module.scss";

export const DevPasswordOverlay = ({ kioskMode }) => {
    const [cookies, setCookie, removeCookie] = useCookies([
        cookieParameterMap.devModeValidated,
    ]);
    const [displayDevPasswordOverlay, setDisplayDevPasswordOverlay] =
        useState(true);
    const [passwordInvalid, setPasswordInvalid] = useState(false);

    const passwordInputRef = useRef(null);

    useEffect(() => {
        let devModeValidatedCookie = "false";

        if (cookies[cookieParameterMap.devModeValidated]) {
            devModeValidatedCookie =
                cookies[cookieParameterMap.devModeValidated];
        }

        if (devModeValidatedCookie == "true") {
            setDisplayDevPasswordOverlay(false);
        }
    }, []);

    const validatePassword = () => {
        const input = passwordInputRef.current.value;

        if (input == "CodBearMouse") {
            setCookie(cookieParameterMap.devModeValidated, "true", {
                path: "/",
                expires: getCookieExpirationDate(kioskMode),
                sameSite: "strict",
            });
            setDisplayDevPasswordOverlay(false);
        } else {
            setPasswordInvalid(true);
        }
    };

    return (
        <React.Fragment>
            {displayDevPasswordOverlay == true && (
                <div className={styles["dev-password-overlay"]}>
                    <div className={styles["dev-password-container"]}>
                        <h1 className={styles["dev-password-heading"]}>
                            This is a CreateMe Development Site
                        </h1>
                        <p className={styles["dev-password-text"]}>
                            Enter password to continue
                        </p>

                        {passwordInvalid == true && (
                            <p className={styles["input-error"]}>
                                Incorrect password. Please try again.
                            </p>
                        )}

                        <div className={styles["dev-password-input"]}>
                            <input
                                ref={passwordInputRef}
                                type="password"
                                onKeyUp={(event) => {
                                    if (event.keyCode == 13) validatePassword();
                                }}
                            ></input>
                        </div>
                        <button
                            onClick={(event) => {
                                validatePassword();
                            }}
                        >
                            Enter Site
                        </button>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};
