/* eslint-disable */
export const getKioskModeFromKioskString = (kiosk: string) => {
    let kioskMode = false;

    if (kiosk == "true") {
        kioskMode = true;
    }

    return kioskMode;
};
