// cookie-parameter-map.ts
// Author : Matthew Tinn
// Date : 18/08/2021
// An object containing names that are used for cookies and query string parameters

export const cookieParameterMap = {
    store: "store", // Store data from the store map JSON
    devModeValidated: "devModeValidated", // Turn on/off dev password
    kiosk: "kiosk", // Kiosk mode
    locale: "locale", // Chosen locale
    disableLangChange: "disableLangChange", // Remove language change from nav
    debug: "debug", // Enable/disable debug mode
    mode: "mode", // Entry mode into configurator from homepage
    state: "state", // Used to enter direct to configure mode
    CCO: "CCO", // CCO to load
    quickAdd: "quickAdd", // Enables quick add on checkout
    localPrint: "localPrint", // Enables print receipt functionality
    barcodeFormat: "barcodeFormat", // Sets default barcode format
    collectionNote: "collectionNote", // Enables collection note on checkout
    confirmedOrders: "confirmedOrders", // Bypass print manager on new order
    orderId: "orderId", // Order ID for confirmation screen,
    disableStorePickup: "disableStorePickup", // Disables COLLECTION on checkout
};
