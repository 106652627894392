
import { createContext } from "react";
import { getCookieExpirationDate } from "../helpers/get-cookie-expiration-date";

export const setAnalyticsDisabled = (analyticsState: any, setAnalyticsState: any, setCookie: any, analyticsChoice: boolean) => {    
    const newState = { ...analyticsState };
    newState.analyticsDisabled = analyticsChoice;
    setAnalyticsState(newState);
    setCookie("analyticsDisabledCookie", analyticsChoice, { path: '/', expires: getCookieExpirationDate()});
}

const AnalyticsContext = createContext({
    analytics: {},
    setAnalyticsDisabled: null
});

export default AnalyticsContext;